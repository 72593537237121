<script setup>
    import { ref, computed, onMounted, watch } from 'vue';
    const props = defineProps(['row', 'connections']);

    const attachmentConnections = ref([]);

    // Total items to show before the container overflows (to +n) can be changed here
    const connectionsWithoutOverflow = computed(() => attachmentConnections?.value.length > 2 ? 2 : attachmentConnections?.value.length);

    onMounted(() => {
        makeButtons();
    });

    watch(()=>props.connections, (stuff)=>{
        makeButtons();
    });

    async function makeButtons(){
        let arr = [];
        for (let i = 0; i < props.connections?.length; i++){
            let connection = props.connections[i];
            var add = true;
            if (Object.hasOwn(connection, 'connectionCheck')) {
                add = await connection.connectionCheck(props.row);
            }
            if(add){
                arr.push(connection);
            }
        }
        attachmentConnections.value = arr;
    }
</script>

<template>
    <div v-if="attachmentConnections && attachmentConnections.length > 0" class="dropdown">
        <div class="position-absolute bg-white top-0 end-0 d-flex p-1 gap-2 border border-top-0 border-end-0"
            style="border-bottom-left-radius: 0.3rem;cursor: pointer;"
            data-bs-toggle="dropdown"
            aria-expanded="false">
            <div v-for="i in connectionsWithoutOverflow" class="small bi" :class="attachmentConnections[i - 1].iconClass"></div>
            <div v-if="attachmentConnections.length > connectionsWithoutOverflow" class="small">+{{attachmentConnections.length - (connectionsWithoutOverflow)}}</div>
        </div>
        
        <ul class="dropdown-menu">
            <li v-for="connection in attachmentConnections">
                <button class="dropdown-item" @click="connection.onClick(row)">
                    <i class="bi me-1" :class="connection.iconClass"></i>
                    {{connection.title}}
                </button>
            </li>
        </ul>
    </div>
</template>